<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('lead.leadStatus.title')"
    class="px-5 py-3"
    v-if="clientAgentStatus"
  >
    <ul>
      <li class="my-4">
        {{ clientAgentStatus.status }}
      </li>
    </ul>
    <div class="d-flex align-center justify-center">
      <v-btn
        v-if="clientAgentStatus.allow_assign"
        color="success"
        class="bottom--button mr-0"
        @click="assignClient"
      >
        {{ $t('client.btn.assign') }}
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: [],
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      source: (state) => state.lead.form.source,
      clientAgentStatus: (state) => state.lead.form.clientAgentStatus,
      client: (state) => state.lead.form.client,
      assignedAgent: (state) => state.lead.form.assignedAgent,
    }),
    isComplete() {
      return this.source != null && this.client != null && this.assignedAgent != null;
    },
  },
  methods: {
    async assignClient() {
      try {
        this.$store.commit('lead/form/SET_LOADING', true);
        await this.$store.dispatch('client/form/assignClientToAgent', {
          agentUuid: this.assignedAgent.uuid,
          clientUuid: this.client.uuid,
        });
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('client.assignClientToAgent.successMsg'),
          'success',
        );
        await this.$store.dispatch('lead/form/fetchBaseData');
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.$store.commit('lead/form/SET_LOADING', false);
      }
    },
  },
};
</script>
